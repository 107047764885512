import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';

interface Genre {
  name: string;
  selected?: boolean;
  children: Genre[];
}

interface GenreSelectorProps {
  genres: Genre[];
  level: number;
  onChange: (newValues: Genre[]) => void;
}

const GenreSelector: React.FC<GenreSelectorProps> = ({ genres, level, onChange }) => {
  const [collapsed, setCollapsed] = useState<boolean[]>(genres.map(() => level !== 0));

  const toggleCollapse = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    setCollapsed(collapsed.map((c, i) => (i === index ? !c : c)));
  };

  const toggleCheckedIndex = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const newGenres = [...genres];
    const isSelected = !newGenres[index].selected;
    newGenres[index].selected = isSelected;
    if (newGenres[index].children) {
      toggleChildren(newGenres[index].children, isSelected);
    }
    onChange(newGenres);
  };

  const toggleChildren = (children: Genre[], isSelected: boolean) => {
    children.forEach((child) => {
      child.selected = isSelected;
      if (child.children) {
        toggleChildren(child.children, isSelected);
      }
    });
  };

  const childChanged = (index: number, newValues: Genre[]) => {
    const newGenres = [...genres];
    newGenres[index].children = newValues;
    onChange(newGenres);
  };

  return (
    <>
      {genres && (
        <div>
          {genres.map((genre, index) => (
            <div
              key={index}
              style={{
                borderBottom: level === 1 ? '1px solid #e0e0e0' : 'none',
                padding: genre.children && genre.children.length > 1 ? '8px 0' : '8px 0 8px 22px',
                marginLeft: `${level * 16}px`
              }}
            >
              <div className={"genre level-" + level} style={{ display: 'flex', alignItems: 'center' }}>
                {level > 0 && genre.children && genre.children.length > 1 && (
                  <button
                    onClick={(event) => toggleCollapse(index, event)}
                    className="chevron-button"
                    style={{ border: 'none', background: 'none', padding: 0, marginRight: '8px' }}
                  >
                    {collapsed[index] ? <FaChevronRight /> : <FaChevronDown />}
                  </button>
                )}
                <Form.Check
                  label={genre.name}
                  name={genre.name}
                  type="checkbox"
                  checked={genre.selected || false}
                  onChange={(event) => toggleCheckedIndex(index, event)}
                  style={{ display: 'inline-block', flexGrow: 1 }}
                />
              </div>
              {!collapsed[index] && genre.children && genre.children.length > 1 && (
                <GenreSelector
                  genres={genre.children}
                  level={level + 1}
                  onChange={(newValues) => childChanged(index, newValues)}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default GenreSelector;