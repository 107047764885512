import React from 'react';
import '../GroupProfile.scss';

interface Genre {
  id: number;
  name: string;
  category: number;
  selected: boolean;
  parent?: number;
}

interface SubGroup {
  id: number;
  name: string;
  parent: number;
  selected: boolean;
  children: Genre[];
}

interface Genre {
  name: string;
  children: SubGroup[];
}

interface GroupGenresProps {
  groupProfileData: {
    genres2_set: Genre[];
  };
}

const GroupGenres: React.FC<GroupGenresProps> = ({ groupProfileData }) => {
  return (
    <>
      <h4>GENRES</h4>
      <div className="genres">
        {groupProfileData.genres2_set.map((group, groupIndex) => (
          <div key={groupIndex} className="genre-group">
            <h4 className='super-category'>{group.name}</h4>
            {group.children.map((subGroup, subGroupIndex) => {
              const selectedGenres = subGroup.children
                .filter((genre) => genre.selected)
                .map((genre) => genre.name);

              if (selectedGenres.length === 0) {
                return null;
              }

              return (
                <h5 key={subGroup.id}>
                  {subGroup.name} ({selectedGenres.join(', ')})
                </h5>
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export default GroupGenres;