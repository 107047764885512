import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Footer.scss";
import { FormattedMessage } from "react-intl";
import Contact from "components/contact/Contact";
import sendRequest from "services/dataService";
import facebook_logo from "../../assets/facebook-icon.png";
import twitter_logo from "../../assets/twitter-icon.png";
import { Button } from "react-bootstrap";
import Config from "../../config";
import { Link } from "react-router-dom";
import useFetch from "custom-hooks/useFetch";
const BASE_API_URL = Config.BASE_API_URL;

const Footer = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [email, setEmail] = useState('');
  const [state, fetchData] = useFetch();
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscribedMessage, setSubscribedMessage] = useState('');
  const [subscribedErrorMessage, setSubscribedErrorMessage] = useState('');

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleOpenModal = () => {
    setShowContactModal(true);
  };

  const handleCloseModal = () => {
    setShowContactModal(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const data = {
      email: email
    };
    fetchData({
      url: BASE_API_URL + 'subscribe/',
      method: "POST",
      body: JSON.stringify(data),        
    })
    .then((response: any) => {
      setSubscribedMessage(response.response);
      setSubscriptionSuccess(true);
    })
    .catch((error: any) => {
        setSubscribedErrorMessage(error.response || 'Subscription failed');
    });
  };

  return (
    <footer className="ink-footer bd-footer py-5 text-white" id="iv_footer">
      <div className="container fit-container">
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <p>
              <FormattedMessage id="footer.inkedvoicesPurpose" />
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="col-xs-4 col-sm-4">
              <ul className="list-unstyled">
              <li className="mb-2">
                  <Link
                    to="/pricing/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.membershipPlans" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/testimonials/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.testimonials" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/code-of-conduct/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.codeofConduct" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/faq/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.faq" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-4 col-sm-4">
              <ul className="list-unstyled">
              <li className="mb-2">
                  <Link
                    className="text-decoration-none link-light"
                    to="/team/"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.team" />
                  </Link>
                </li>
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "about/principles/"}
                  >
                    <FormattedMessage id="footer.guidingPrinciples" />
                  </a>
                </li>
                <li className="mb-2">
                  <Link
                    to="/educators-and-writing-programs/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.hostYourWorkshop" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-4 col-sm-4">
              <ul className="list-unstyled">
              <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "help/"}
                  >
                    <FormattedMessage id="footer.help" />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "writing/"}
                  >
                    <FormattedMessage id="footer.writingGroups101" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3">
            <ul className="list-unstyled">
              <li className="mb-2">
                <a
                  className="text-decoration-none link-light"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenModal();
                  }}
                >
                  <FormattedMessage id="footer.contactUs" />
                </a>
                <Contact show={showContactModal} handleClose={handleCloseModal} />
                <a href="https://www.facebook.com/InkedVoicesLLC">
                  <img
                    alt="facebook-logo"
                    src={facebook_logo}
                    className="social-media-icon"
                  />
                </a>
              </li>
              <li className="mb-2 newsletter-link">
                {subscriptionSuccess ? (
                  <span className="subscript_success" style={{ display: 'inline-block' }}>
                    <i>{subscribedMessage}</i>
                  </span>
                ) : (
                  <form
                    role="form"
                    id="subscribe_form"
                    className="form-inline"
                    onSubmit={handleSubscribe}
                  >
                    <div className="form-group">
                      <input
                        placeholder="Email address"
                        className="form-control"
                        value={email}
                        onChange={handleEmailChange}
                        id="id_email"
                      />
                      <Button type="submit" className="yellow" id="button_go">
                        Join our newsletter
                      </Button>
                    </div>
                    {subscribedErrorMessage && (
                      <div className="error-message" style={{ color: 'red' }}>
                        {subscribedErrorMessage}
                      </div>
                    )}
                  </form>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div id="footer-copyright" className="col-xs-8">
                © 2023 Inked Voices, LLC. All Rights Reserved.{" "}
                <span id="footer-enjoy">Enjoy the rest of your day!</span>
              </div>
              <div id="footer-terms" className="col-xs-4 pull-right">
                <Link
                  to="/terms-of-service/"
                  className="text-decoration-none link-light"
                  onClick={scrollToTop}
                >
                  <FormattedMessage id="footer.termsOfService" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
