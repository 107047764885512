import './GroupMember.scss';
import ReactPlaceholder from "react-placeholder";
import { Link } from "react-router-dom";

interface GroupMemberProps {
  member?: {
    id: number;
    avatar?: string;
    pen_name: string;
    profile?: number;
    bio: string;
    organizations: string;
  };
}

const GroupMemberCol: React.FC<GroupMemberProps> = ({ member }) => {
  return (
    <li className="item">
      <div className="row">
        <div className="col-md-3">
          <ReactPlaceholder
            type="rect"
            ready={Boolean(member?.avatar)}
            style={{ width: 91, height: 91 }}
          >
            <Link to={`/profile/view/${member?.profile}`}>
              <img src={member?.avatar} className="w-full" alt={member?.pen_name} />
            </Link>
          </ReactPlaceholder>
        </div>
        <div className="col-md-8">
              <Link
                to={`/profile/view/${member?.profile}`}
                style={{ color: "black" }}
              >
                {member?.pen_name}
              </Link>
              <p>Bio:  {member?.bio && member.bio.length > 70 ? `${member.bio.substring(0, 70)}...` : member?.bio}</p>
              <p>Organizations: {member?.organizations}</p>
        </div>
      </div>
    </li>
  );
};

export default GroupMemberCol;