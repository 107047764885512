import React from "react";

type IVTableProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  style?: React.CSSProperties;
};
export function IVTable({ style, children }: IVTableProps) {
  return (
    <table
      width="100%"
      style={style}
      className="table table-striped critique-table"
    >
      {children}
    </table>
  );
}

export function IVTableHead({ children }: IVTableProps) {
  return <thead>{children}</thead>;
}

export function IVTr({ children, style }: IVTableProps) {
  return (
    <tr style={style} className="no-zebra">
      {children}
    </tr>
  );
}

export function IVTh({ children, className }: IVTableProps) {
  return (
    <th scope="col" className={className}>
      {children}
    </th>
  );
}

export function IVTd({ children, style, className }: IVTableProps) {
  return (
    <td style={style} className={className}>
      {children}
    </td>
  );
}

export function IVTableBody({ children, style }: IVTableProps) {
  return <tbody style={style}>{children}</tbody>;
}

export function IVTableSeparator({ cols }: { cols: number }) {
  return (
    <tr>
      {[...Array(cols)].map((_, i) => (
        <td key={i} className="purple-row"></td>
      ))}
    </tr>
  );
}
