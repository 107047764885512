import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { adjustTextareaHeight } from "utils";

const NewMessageForm = ({
  groupId,
  parentId,
  onPostMessage,
}: {
  groupId: number;
  parentId?: number;
  onPostMessage: () => void;
}) => {
  const authenticationContext = useContext(AuthenticationContext);
  const [lastSavedTime, setLastSavedTime] = useState<Date | null>(null);
  const [commentId, setCommentId] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  const fetchDraft = async () => {
    const textarea = ref.current;
    if (textarea) {
      textarea.value = "";
    }
    const url = parentId
      ? `api/v1.0/group/${groupId}/comments/?self=true&draft=true&parent=${parentId}`
      : `api/v1.0/group/${groupId}/comments/?self=true&draft=true`;
    sendRequest({
      method: "GET",
      url: url,
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      if (response.count > 0) {
        setCommentId(response.results[0].id);
        if (textarea) {
          textarea.value = response.results[0].text;
        }
      } else {
        if (textarea) {
          textarea.value = "";
        }
      }
    });
  };
  const onMessagePost = (e: React.FormEvent, draft: boolean = false) => {
    e.preventDefault();
    if (!ref.current) {
      return;
    }
    const textArea = ref.current as HTMLTextAreaElement;
    const text = ref.current?.value;
    const token = authenticationContext.token || "";
    sendRequest({
      method: commentId ? "PATCH" : "POST",
      url: commentId
        ? `api/v1.0/group/${groupId}/comments/${commentId}/`
        : `api/v1.0/group/${groupId}/comments/`,
      body: JSON.stringify({ text: text, parent: parentId, draft: draft }),
      token,
    }).then((response) => {
      if (draft) {
        setCommentId(response.id);
        setLastSavedTime(new Date());
      } else {
        toast.success("Comment posted", {
          position: "bottom-right",
        });
        setCommentId(null);
        setLastSavedTime(null);
        textArea.value = "";
        adjustTextareaHeight(textArea, 30);
        onPostMessage();
      }
    });
  };

  useEffect(() => {
    fetchDraft();
    setLastSavedTime(null);
    setCommentId(null);
  }, [groupId, parentId]);
  return (
    <form onSubmit={onMessagePost}>
      <div className="textarea-holder">
        <textarea
          name="text"
          cols={40}
          rows={2}
          style={{ height: 30 }}
          maxLength={5000}
          placeholder="Ask a question, answer one or make a comment."
          required
          id="id_text"
          ref={ref}
          onKeyUp={(e) =>
            adjustTextareaHeight(e.target as HTMLTextAreaElement, 30)
          }
          onChange={(e) => {
            const value = e.target.value;
            setTimeout(() => {
              if (value && value === e.target.value) {
                onMessagePost(e, true);
                setLastSavedTime(new Date());
              }
            }, 1000);
          }}
        />
      </div>
      <p className="submit">
        <input
          type="submit"
          name="post"
          className="submit-post btn"
          style={{
            fontSize: parentId ? "12px" : "16px",
            paddingRight: parentId ? "7px" : "30px",
            paddingLeft: parentId ? "7px" : "30px",
          }}
          value="Post"
        />

        {lastSavedTime && (
          <span className="last-sync pl-4">
            Saved as draft (
            {lastSavedTime.toLocaleTimeString("en-US", {
              timeStyle: "short",
            })}
            )
          </span>
        )}
      </p>
    </form>
  );
};

export default NewMessageForm;
