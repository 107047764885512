import React, { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import AuthenticationContext from "contexts/AuthenticationContext";
import { updateUserAvatar } from "redux/userReducer";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import config from "config";
import Config from "../../../../config";
import useFetch from "custom-hooks/useFetch";
import GenreSelector from "components/genre-selector/GenreSelector";
import MultiSelect, {
  MultiSelectOption,
} from "components/multi-select/MultiSelect";

import "../EditProfile.scss";
import { Publication, FullUserProfile } from "types";
import CharCountInput from "components/char-count-input/CharCountInput";
import { getGenreListIds } from "utils";
import { searchingForGroupOptions } from "options/SearchingForGroup";
import { pronounOptions } from "options/Pronouns";


const ProfileForm = ({ profileData }: { profileData: FullUserProfile }) => {
  const [state, fetchData] = useFetch();
  const user = useSelector((state: any) => state.user);
  const authenticationContext = useContext(AuthenticationContext);
  const history = useHistory();

  useEffect(() => {
    profileData?.publication_set.forEach(
      (publication: Publication, index: number) => {
        setImagePreviewUrls((prev) => ({
          ...prev,
          [index]: publication.image,
        }));
      }
    );
  }, [profileData]);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FullUserProfile>({
    defaultValues: profileData,
    mode: "onBlur",
  });
  const {
    fields: publicationFields,
    append: publicationAppend,
    remove: publicationRemove,
  } = useFieldArray({ control, name: "publication_set" });
  const {
    fields: quoteFields,
    append: quoteAppend,
    remove: quoteRemove,
  } = useFieldArray({ control, name: "quote_set" });

  const [genres, setGenres] = useState(profileData.genres2_set);
  const [critiquingGenres, setCritiquingGenres] = useState(
    profileData.critiquing_genres2_set
  );
  const [allAuthors, setAllAuthors] = useState<any[]>([]);
  const [allBooks, setAllBooks] = useState<any[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<any[]>([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]);

  const fetchAuthors = async () => {
    fetchData({
      url: "profile/api/authors/",
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setAllAuthors(res);
    });
  };

  const fetchBooks = async () => {
    fetchData({
      url: "profile/api/books/",
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setAllBooks(res);
    });
  };

  const fetchOrganizations = async () => {
    fetchData({
      url: "profile/api/organizations/",
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setAllOrganizations(res);
    });
  };

  useEffect(() => {
    fetchAuthors();
    fetchBooks();
    fetchOrganizations();
  }, [authenticationContext, fetchData]);

  const uploadImageChange = async (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        setImagePreviewUrls((prev) => ({
          ...prev,
          [index]: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const onAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("avatar", file);

      try {
        const response = await axios.put(
          `${config.BASE_API_URL}profile/api/change-avatar/`,
          formData,
          {
            headers: {
              Authorization: "Token " + (authenticationContext.token || ""),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Dispatch the action to update the user avatar
        dispatch(updateUserAvatar(response.data.avatar));

        toast.success("Avatar updated successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (error) {
        toast.error("Failed to update avatar.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  const onSubmit: SubmitHandler<FullUserProfile> = async (data) => {
    if (
      data.quote_set.filter(
        (item: any) => item.text !== "" || item.author !== ""
      ).length === 0
    ) {
      data.quote_set = [];
    }

    if (
      data.publication_set.every(
        (item: any) =>
          item.title === "" &&
          item.publisher === "" &&
          item.year === 0 &&
          item.url === ""
      )
    ) {
      data.publication_set = [];
    } else {
      data.publication_set.forEach((item: Publication, index: number) => {
        item.image = imagePreviewUrls[index];
      });
    }

    data.genres2_set = getGenreListIds(genres);
    data.critiquing_genres2_set = getGenreListIds(critiquingGenres);

    fetchData({
      url: "profile/api/edit/",
      method: "PUT",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then(() => {
        toast.success("Success! Your submission has been saved.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // redirect to the profile page after successful submission
        history.push(`/profile/view/${profileData.id}`);
      })
      .catch((error) => {
        // I assume that error.response contains the response body with the errors
        if (error.message) {
          let errorData;
          try {
            // Try to parse error.response.data if it's a string
            errorData = typeof error.message === 'string' ? JSON.parse(error.message) : error.response.data;
          } catch (e) {
            // If parsing fails, handle the error or assign a default value to errorData
            errorData = { message: "An error occurred, but the details could not be parsed." };
          }
        
          let errorMessage = "Failed to save changes.";
          if (typeof errorData === 'object' && errorData !== null) {
            if (errorData.message) {
              // If errorData contains a message property, use it as errorMessage
              errorMessage = errorData.message;
            } else {
              // If errorData doesn't contain a message property, build the error message based on the properties of errorData
              errorMessage = Object.entries(errorData).map(([field, value]) => {
                // Sure that value is an array before trying to join its elements.
                const errors = Array.isArray(value) ? value.join(", ") : "Unknown error";
                return `${field}: ${errors}`;
              }).join("\n");
            }
          }
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("Failed to save changes and could not parse error details.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  return (
    <Container className="container ink-profile-edit">
      <Row>
        <Col md={{ span: 12, offset: 0 }}>
          <h1>Edit Account Profile</h1>
          <a href={`/profile/view/${profileData.id}`} target="_blank" rel="noopener noreferrer">View profile</a>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={{ span: 3, offset: 0 }}>
            <label>Name</label>
          </Col>
          <Col md={{ span: 2, offset: 0 }}>{user.data.pen_name}</Col>
          <Col md={{ span: 7, offset: 0 }}>
            <a href={Config.BASE_API_URL + "account/edit"}>Change</a>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 3, offset: 0 }}>
            <label>Profile photo</label>
          </Col>
          <Col md={{ span: 2, offset: 0 }}>
            <img className="profile-picture" src={user.data.avatar} />
          </Col>
          <Col md={{ span: 7, offset: 0 }}>
            Change your profile picture
            <br />
            <br />
            <input type="file" onChange={onAvatarChange} />
            <div id="file-uploader"></div>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 3, offset: 0 }}>
            <h2>Pronouns</h2>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <ul id="id_pronouns" className="custom-checkbox horizontal-align">
              {pronounOptions.map((option, index) => (
                <li key={`id_pronouns_${index}`} className="custom-checkbox">
                  <label className="custom-checkbox">
                    <input
                      {...register("pronouns")}
                      type="radio"
                      value={option.value}
                      className="custom-checkbox"
                    />{" "}
                    {option.label}
                  </label>
                </li>
              ))}
            </ul>
          </Col>
        </Row>

        <h2>What is your background?</h2>

        <CharCountInput
          maxLength={1000}
          inputName="bio"
          register={register}
          errors={errors}
          defaultValue={profileData.bio}
          label="Writer's statement or bio *"
          fieldDescription="This is a short description of yourself as a writer. Include what you write, your goals or all of the above."
          placeholder="This is a short description of yourself as a writer. Include what you write, your goals or all of the above."
        />

        <Row>
          <Col md={{ span: 3, offset: 0 }}>
            <label>What is your genre? *</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <Controller
              name="genres2_set"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <GenreSelector
                    level={0}
                    genres={genres}
                    onChange={(e: any) => {
                      setGenres(e);
                    }}
                  />
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 3, offset: 0 }}>
            <label>Are you an illustrator?</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <input {...register("is_illustrator")} type="checkbox" /> Yes
          </Col>
        </Row>

        <CharCountInput
          maxLength={1000}
          inputName="experience"
          register={register}
          errors={errors}
          defaultValue={profileData.experience}
          label="Writing education / experience"
          placeholder="Tell us about your writing education or experience."
        />

        <CharCountInput
          maxLength={1000}
          inputName="non_writing_experience"
          register={register}
          errors={errors}
          defaultValue={profileData.non_writing_experience}
          label="Non-writing Expertise"
          fieldDescription="Share any areas of specialized knowledge on which you'd be open to question from fellow writers."
          placeholder="Share any areas of specialized knowledge on which you'd be open to question from fellow writers."
        />

        <Row>
          <Col md={{ span: 3, offset: 0 }}>
            <label>Publications</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            {publicationFields.map((publicationFields, index) => {
              return (
                <Row
                  id={`${publicationFields.id}-row`}
                  className="publication dynamic-form simple"
                >
                  <Col md={{ span: 10, offset: 0 }}>
                    <input
                      placeholder="title"
                      type="text"
                      {...register(`publication_set.${index}.title` as const)}
                      className={
                        errors?.publication_set?.[index]?.title
                          ? "error"
                          : "formset inline-form-input"
                      }
                    />
                  </Col>
                  <Col md={{ span: 8, offset: 0 }}>
                    <input
                      placeholder="publisher"
                      type="text"
                      {...register(
                        `publication_set.${index}.publisher` as const
                      )}
                      className={
                        errors?.publication_set?.[index]?.publisher
                          ? "error"
                          : "inline-form-input"
                      }
                    />
                  </Col>
                  <Col
                    md={{ span: 2, offset: 0 }}
                    className="publication-year-input"
                  >
                    <input
                      placeholder="year"
                      type="number"
                      {...register(`publication_set.${index}.year` as const, {
                        valueAsNumber: true,
                      })}
                      className={
                        errors?.publication_set?.[index]?.year
                          ? "error"
                          : "inline-form-input"
                      }
                    />
                  </Col>
                  <Col md={{ span: 10, offset: 0 }}>
                    <input
                      placeholder="url"
                      type="text"
                      {...register(`publication_set.${index}.url` as const)}
                      className={
                        errors?.publication_set?.[index]?.url
                          ? "error"
                          : "inline-form-input"
                      }
                    />
                  </Col>
                  <Col
                    md={{ span: 10, offset: 0 }}
                    id={`publication_set.${index}-preview`}
                  >
                    {imagePreviewUrls[index] && (
                      <img
                        src={imagePreviewUrls[index]}
                        alt="Image preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id={`publication_set.${index}.image`}
                      {...register(`publication_set.${index}.image` as const)}
                      onChange={(event) => {
                        // Asegúrate de que la aserción de tipo aquí coincide con los tipos esperados por `register`.
                        (register as any)(
                          `publication_set.${index}.image`
                        ).onChange(event);
                        uploadImageChange(index, event);
                      }}
                    />
                    <label
                      htmlFor={`publication_set.${index}.image`}
                      style={{ cursor: "pointer" }}
                      className="btn btn-primary upload-image-btn"
                    >
                      Upload an Image
                    </label>
                  </Col>
                  <Col md={{ span: 12, offset: 0 }}>
                    <button
                      type="button"
                      className="delete-row"
                      onClick={() => publicationRemove(index)}
                    >
                      - Remove Publication
                    </button>
                  </Col>
                </Row>
              );
            })}
            <button
              type="button"
              className="add-row"
              onClick={() =>
                publicationAppend({
                  title: "",
                  publisher: "",
                  year: 0,
                  url: "",
                  image: "",
                })
              }
            >
              + Add another publication
            </button>
          </Col>
        </Row>

        <h2>Your Personal Information</h2>

        <div className="personal-information">
          <Row>
            <Col md={{ span: 3, offset: 0 }}>
              <label>City</label>
            </Col>
            <Col md={{ span: 9, offset: 0 }}>
              <Row className="simple">
                <Col md={{ span: 5, offset: 0 }}>
                  <input type="text" {...register("city")} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={{ span: 3, offset: 0 }}>
              <label>State</label>
            </Col>
            <Col md={{ span: 9, offset: 0 }}>
              <Row className="simple">
                <Col md={{ span: 5, offset: 0 }}>
                  <input type="text" {...register("state")} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={{ span: 3, offset: 0 }}>
              <label>Country</label>
            </Col>
            <Col md={{ span: 9, offset: 0 }}>
              <Row className="simple">
                <Col md={{ span: 5, offset: 0 }}>
                  <input type="text" {...register("country")} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>Personal sites</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <Row className="simple">
              <Col md={{ span: 5, offset: 0 }}>
                <input
                  type="url"
                  {...register("facebook")}
                  className="inline-form-input"
                  placeholder="https://www.facebook.com/JoeDoe"
                />
                <input
                  type="url"
                  {...register("linkedin")}
                  className="inline-form-input"
                  placeholder="http://ar.linkedin.com/in/JoeDoe"
                />
                <input
                  type="url"
                  {...register("goodreads")}
                  className="inline-form-input"
                  placeholder="https://www.goodreads.com/JoeDoe"
                />
              </Col>
              <Col md={{ span: 5, offset: 0 }}>
                <input
                  type="url"
                  {...register("twitter")}
                  className="inline-form-input"
                  placeholder="https://twitter.com/joeDoe"
                />
                <input
                  type="url"
                  {...register("instagram")}
                  className="inline-form-input"
                  placeholder="https://instagram.com/JoeDoe"
                />
                <input
                  type="url"
                  {...register("personal_website")}
                  className="inline-form-input"
                  placeholder="https://personalwebsite.com"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>Favorite authors</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <MultiSelect
              key="favorite_authors"
              name={"favorite_authors"}
              control={control}
              options={allAuthors}
              isCreatable={true}
              entityType="author"
              onCreate={(newOption: MultiSelectOption) => fetchAuthors()}
            />
          </Col>
        </Row>

        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>Favorite books</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <MultiSelect
              key="favorite_books"
              name={"favorite_books"}
              control={control}
              options={allBooks}
              isCreatable={true}
              entityType="book"
              onCreate={(newOption: MultiSelectOption) => fetchBooks()}
            />
          </Col>
        </Row>

        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>Favorite quotes</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            {quoteFields.map((quoteFields, index) => {
              return (
                <Row
                  id={`${quoteFields.id}-row`}
                  className="quote dynamic-form row simple quote-formset"
                >
                  <Col md={{ span: 12, offset: 0 }}>
                    <textarea
                      placeholder="Quote"
                      {...register(`quote_set.${index}.text` as const)}
                      className={
                        errors?.quote_set?.[index]?.text ? "error" : "quote"
                      }
                    />
                  </Col>
                  <Col md={{ span: 6, offset: 0 }}>
                    <input
                      placeholder="Author"
                      type="text"
                      {...register(`quote_set.${index}.author` as const)}
                      className={
                        errors?.quote_set?.[index]?.author ? "error" : ""
                      }
                    />
                  </Col>
                  <Col md={{ span: 12, offset: 0 }}>
                    <button
                      type="button"
                      className="delete-row"
                      onClick={() => quoteRemove(index)}
                    >
                      - Remove Quote
                    </button>
                  </Col>
                </Row>
              );
            })}
            <button
              type="button"
              className="add-row"
              onClick={() => quoteAppend({ text: "", author: "" })}
            >
              + Add another favorite quote
            </button>
          </Col>
        </Row>

        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>
              Organizations
              <br />
              <OverlayTrigger
                trigger="click"
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover id={`popover-positioned-right`}>
                    <p>
                      Other people can search for people belonging to particular
                      groups. This can help you to find other with similar
                      intrests or backgrounds. Examples: SCBWI, SATW.
                    </p>
                  </Popover>
                }
              >
                <Button className="ico-help" />
              </OverlayTrigger>
            </label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <MultiSelect
              key="organizations"
              name={"organizations"}
              control={control}
              options={allOrganizations}
              isCreatable={true}
              entityType="organization"
              onCreate={(newOption: MultiSelectOption) => fetchOrganizations()}
            />
          </Col>
        </Row>

        <h2>Writing group preferences</h2>
        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>Are you currently looking for a writing group</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <ul id="id_searching_for_group" className="custom-checkbox">
              {searchingForGroupOptions.map((option, index) => (
                <li key={`id_searching_for_group_${index}`} className="custom-checkbox">
                  <label className="custom-checkbox">
                    <input
                      {...register("searching_for_group")}
                      defaultChecked={profileData.searching_for_group === option.value}
                      type="radio"
                      value={option.value}
                      className="custom-checkbox"
                    />{" "}
                    {option.label}
                  </label>
                </li>
              ))}
            </ul>
          </Col>
        </Row>

        <CharCountInput
          maxLength={1000}
          inputName="looking_in_a_group"
          register={register}
          errors={errors}
          defaultValue={profileData.looking_in_a_group}
          label="What are you looking for in a writing group?"
        />

        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}>
            <label>What genres are you open to critiquing?</label>
          </Col>
          <Col md={{ span: 9, offset: 0 }}>
            <Controller
              name="critiquing_genres2_set"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <GenreSelector
                    genres={critiquingGenres}
                    level={0}
                    onChange={(e: any) => {
                      setCritiquingGenres(e);
                    }}
                  />
                );
              }}
            />
          </Col>
        </Row>

        <CharCountInput
          maxLength={1000}
          inputName="critiquing_style"
          register={register}
          errors={errors}
          defaultValue={profileData.critiquing_style}
          label="What is your critiquing style?"
        />

        <CharCountInput
          maxLength={1000}
          inputName="looking_for_in_critique"
          register={register}
          errors={errors}
          defaultValue={profileData.looking_for_in_critique}
          label="What are you looking for in critiques from others?"
        />

        <CharCountInput
          maxLength={1000}
          inputName="writing_sample"
          register={register}
          errors={errors}
          defaultValue={profileData.writing_sample}
          label="Writing Sample"
        />

        <h2>Privacy</h2>
        <Row className="row">
          <Col md={{ span: 3, offset: 0 }}></Col>
          <Col md={{ span: 9, offset: 0 }}>
            <input {...register("hide_from_search")} type="checkbox" />{" "}
            <span className="italic">
              Your profile can be viewed by fellow Inked Voices members who are
              logged in.
              <br />
              If you <b>do not</b> want your name to come up in Inked Voices
              searches, check this box.
            </span>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 9, offset: 3 }} className="main-btn">
            <input
              type="submit"
              className="btn save-changes"
              value="Save Changes"
              style={{ marginRight: "1%" }}
            />
            <a href={Config.BASE_API_URL} className="btn cancel">
              Cancel
            </a>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default ProfileForm;
