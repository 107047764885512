import DeleteCommentModal from "components/groups/details/groupDetailDeleteCommentModal";
import AuthenticationContext from "contexts/AuthenticationContext";
import { displayCondensedDate } from "pages/events/utils";
import { useContext, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { CurrentUserState, DiscussionComment } from "types";
import NewMessageForm from "../new-message-component/DiscussionNewCommentComponent";
import { useSelector } from "react-redux";
import EditDiscussionCommentModal from "components/groups/details/groupDetailEditDiscussionCommentModal";
import { Link } from "react-router-dom";
import config from "config";
import { AutoLink } from "utils";

type DiscussionCommentComponentProps = {
  comment: DiscussionComment;
  parent?: DiscussionComment;
  isAdmin?: boolean;
  expandByDefault?: boolean;
  onPinUnpin: (pinned: boolean) => void;
  onDelete: () => void;
  onReply: () => void;
};

export default function DiscussionCommentComponent({
  comment,
  parent,
  isAdmin,
  expandByDefault = false,
  onPinUnpin,
  onDelete,
  onReply,
}: DiscussionCommentComponentProps) {
  const hasReplies = comment.replies?.length > 0;
  const [showReplies, setShowReplies] = useState(expandByDefault);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const authenticationContext = useContext(AuthenticationContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  const handlePinUnpin = (comment: DiscussionComment) => {
    const token = authenticationContext.token || "";
    sendRequest({
      method: "PATCH",
      url: `api/v1.0/group/${comment.target_instance_id}/comments/${comment.id}/`,
      token,
      body: JSON.stringify({ pinned: !comment.pinned }),
    }).then((response) => {
      onPinUnpin(!comment.pinned);
      toast.success(
        comment.pinned
          ? "Comment unpinned successfully."
          : "Comment pinned successfully.",
        {
          position: "bottom-right",
        }
      );
    });
  };

  const onEdit = () => {
    onPinUnpin(false);
  };

  const handleDelete = async (comment: DiscussionComment) => {
    const token = authenticationContext.token || "";
    try {
      const response = await sendRequest({
        method: "DELETE",
        url: `api/v1.0/group/${comment.target_instance_id}/comments/${comment.id}/`,
        token,
        body: null,
      });
      toast.success("Comment deleted successfully.", {
        position: "bottom-right",
      });
    } catch (error: any) {
      // toast.error(error.message, {
      //   position: "bottom-right",
      // });
    }
    onDelete();
  };
  const maxReplyLevel = 5;

  return (
    <>
      <Row
        className={"mb-2" + (parent ? " border-bottom" : "")}
        style={
          parent ? { paddingRight: 0, minWidth: "100%", marginRight: 0 } : {}
        }
      >
        <Col
          className={"text-xs"}
          style={{
            width: 68,
            height: userState.data.id === comment.user.id ? "60px" : "40px",
            textAlign: "center",
            maxWidth: "68px",
            margin: 0,
            padding: 0,
            float: "left",
          }}
        >
          <p
            style={{
              margin: 0,
              padding: 0,
              lineHeight:
                userState.data.id === comment.user.id ? "60px" : "40px",
              fontSize: "9px",
              fontWeight: "bold",
              width: 68,
            }}
          >
            {displayCondensedDate(comment.date_created)}
          </p>
        </Col>
        <Col className="p-0">
          <Card className={parent ? "border-0" : ""}>
            <div className="p-3">
              {isAdmin
                ? !parent && (
                    <a
                      href="#"
                      className="text-xs font-bold"
                      style={{ position: "absolute", top: 1, right: 8 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePinUnpin(comment);
                      }}
                    >
                      {comment.pinned ? "Unpin" : "Pin"} this post
                    </a>
                  )
                : comment.pinned && (
                    <span
                      className="text-xs font-bold"
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 8,
                        color: "#0a5c7d",
                      }}
                    >
                      Pinned post
                    </span>
                  )}
              {comment.can_be_deleted && (
                <a
                  href="#"
                  className="delete-button font-bold"
                  style={{
                    position: "absolute",
                    bottom: hasReplies ? 27 : 5,
                    right: 8,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteModal(true);
                  }}
                />
              )}
              <DeleteCommentModal
                comment={comment}
                onHide={(res) => {
                  if (res) {
                    handleDelete(comment);
                  }
                  setShowDeleteModal(false);
                }}
                show={showDeleteModal}
              />
              <EditDiscussionCommentModal
                comment={comment}
                show={showEditModal}
                onHide={(res) => {
                  if (res) {
                    onEdit();
                  }
                  setShowEditModal(false);
                }}
              />
              <Row>
                <Col style={{ fontSize: "14px", lineHeight: "24px" }}>
                  <Link
                    to={`/profile/view/${comment.user.profile}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {comment.user.pen_name}
                  </Link>
                  {parent && (
                    <>
                      {" "}
                      <img
                        src={config.BASE_API_URL + "static/img/reply.png"}
                      />{" "}
                      <Link
                        to={`/profile/view/${parent.user.id}`}
                        style={{ fontWeight: "bold", opacity: 0.6 }}
                      >
                        {parent.user.pen_name}
                      </Link>
                    </>
                  )}
                  :{" "}
                  {comment.text.split("\n").map((line, index) => (
                    <>
                      {index > 0 && <br />}
                      <span key={index}>{AutoLink(line)}</span>
                    </>
                  ))}{" "}
                  {comment.level < maxReplyLevel && (
                    <a
                      href="#"
                      className="font-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowReplyForm(!showReplyForm);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      Reply
                    </a>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {userState.data.id === comment.user.id && (
                    <a
                      href="#"
                      className="font-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowEditModal(true);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      Edit
                    </a>
                  )}
                </Col>
              </Row>
              {showReplyForm && (
                <>
                  <Row style={{ marginTop: 8 }}>
                    <NewMessageForm
                      groupId={comment.target_instance_id}
                      parentId={comment.id}
                      onPostMessage={() => {
                        setShowReplyForm(false);
                        setShowReplies(true);
                        onReply();
                      }}
                    />
                  </Row>
                </>
              )}
            </div>
            {hasReplies && !parent && (
              <div
                className="w-full"
                style={{
                  backgroundColor: "#f1f1f1",
                  textAlign: "right",
                  paddingRight: 8,
                  paddingLeft: 65,
                }}
              >
                <a
                  href="#"
                  className="text-xs font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowReplies(!showReplies);
                  }}
                >
                  {showReplies
                    ? `Hide replies (${comment.replies_count})`
                    : `Show replies (${comment.replies_count})`}
                </a>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      {hasReplies && (showReplies || parent) && (
        <>
          <div style={{ paddingLeft: "60px", paddingRight: 0, width: "100%" }}>
            {comment.replies.map((reply) => (
              <DiscussionCommentComponent
                key={reply.id}
                comment={reply}
                onPinUnpin={onPinUnpin}
                onDelete={onDelete}
                onReply={onReply}
                parent={comment}
                isAdmin={isAdmin}
                expandByDefault={expandByDefault}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
