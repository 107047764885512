import React, { useEffect, useState } from "react";
import {
  Accordion,
  Row,
  Button,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Config from "../../../config";
import "./DashboardComponents.scss";
import ProjectModal from "./modals/ProjectCreateAndRename";
import ArchiveModal from "./modals/ProjectArchive";
import DocumentCreateModal from "./modals/DocumentCreate";
import DocumentDownloadCritiques from "./modals/DocumentDownloadCritiques";
import DocumentSubmitModal from "./modals/DocumentSubmit";
import DocumentInviteModal from "./modals/DocumentInvite";
import DocumentDeleteModal from "./modals/DocumentDelete";
import ReactPlaceholder from "react-placeholder/lib";
import { useCurrentProjects, useQuickSubmissions } from "global-state/projects";
import {
  BaseDocument,
  BaseProject,
  BaseSubmission,
  DashboardSubmission,
} from "types";
import { set } from "lodash";

const backend_url = Config.BASE_API_URL?.slice(0, -1);

type ProjectRowProps = {
  project: BaseProject;
  open: Boolean;
  onChange: () => void;
};

const ProjectRow = ({ project, onChange, open }: ProjectRowProps) => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showNewDocModal, setShowNewDocModal] = useState(false);

  return (
    <Row className="project-row">
      <Col
        className="document-title"
        style={{ paddingLeft: "1.9em", cursor: "pointer" }}
        sm={6}
      >
        <span>{open ? "-" : "+"}</span>&nbsp;
        <img
          className="icon-img"
          src={`${backend_url}/static/img/folder.png`}
          alt="folder"
        />
        &nbsp;
        {project.title}
      </Col>
      <Col className="action-buttons" sm={6}>
        <a href="#" onClick={() => setShowNewDocModal(true)}>
          <Button>Add Doc</Button>
        </a>
        <a href="#" onClick={() => setShowRenameModal(true)}>
          <Button>Rename</Button>
        </a>
        <a href="#" onClick={() => setShowArchiveModal(true)}>
          <Button>Archive</Button>
        </a>
      </Col>
      <DocumentCreateModal
        show={showNewDocModal}
        onHide={() => {
          setShowNewDocModal(false);
        }}
        project={project}
        onChange={onChange}
      />
      <ProjectModal
        show={showRenameModal}
        onHide={() => {
          setShowRenameModal(false);
        }}
        project={project}
        onChange={onChange}
      />
      <ArchiveModal
        show={showArchiveModal}
        onHide={() => {
          setShowArchiveModal(false);
        }}
        project={project}
        onChange={onChange}
      />
    </Row>
  );
};

type DocumentRowComponentProps = {
  document: BaseDocument;
  project: BaseProject;
  inner?: Boolean;
  onChange: () => void;
};

const DocumentRow = ({
  document,
  project,
  inner,
  onChange,
  ...props
}: DocumentRowComponentProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEditDocModal, setShowEditDocModal] = useState(false);
  const [showSubmitDocModal, setShowSubmitDocModal] = useState(false);
  const [showInviteDocModal, setShowInviteDocModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip
      id={`button-tooltip-${document.id}`}
      {...props}
      className="document-options-tooltip"
    >
      <ul>
        {document?.submissions?.length === 0 && (
          <li>
            <a
              href="#"
              onClick={() => {
                setShowEditDocModal(true);
                setShowTooltip(false);
              }}
            >
              Edit
            </a>
          </li>
        )}
        <li>
          <a
            href="#"
            onClick={() => {
              setShowSubmitDocModal(true);
              setShowTooltip(false);
            }}
          >
            Submit
          </a>
        </li>{" "}
        {/* Modal */}
        <li>
          <a
            href="#"
            onClick={() => {
              setShowInviteDocModal(true);
              setShowTooltip(false);
            }}
          >
            Invite
          </a>
        </li>{" "}
        {/* Modal */}
        <li>
          <a href={`${backend_url}/document/download/${document.id}/`}>
            Download
          </a>
        </li>{" "}
        {/* Keep as link */}
        <li>
          <a
            href="#"
            onClick={() => {
              setShowDeleteModal(true);
              setShowTooltip(false);
            }}
          >
            Delete
          </a>
        </li>{" "}
        {/* Modal */}
      </ul>
    </Tooltip>
  );

  return (
    <Row
      className={"project-row document-row" + (inner ? " inner" : "")}
      {...props}
    >
      <Col className="document-title" style={{ paddingLeft: "1.9em" }} sm={9}>
        <a className="table-space" href={`${backend_url}${document.url}`}>
          {document.title}&nbsp;
        </a>
        <span className="sm-text">({document.word_count} words)</span>
      </Col>
      <Col className="action-buttons" sm={3}>
        <OverlayTrigger
          placement="bottom-start"
          overlay={renderTooltip}
          onToggle={setShowTooltip}
          show={showTooltip}
          trigger="click"
        >
          <Button style={{ zIndex: 100 }} variant="primary">
            Options
          </Button>
        </OverlayTrigger>
      </Col>
      {showEditDocModal && (
        <DocumentCreateModal
          show={showEditDocModal}
          onHide={() => {
            setShowEditDocModal(false);
          }}
          project={project}
          document={document}
          onChange={onChange}
        />
      )}
      {showSubmitDocModal && (
        <DocumentSubmitModal
          show={showSubmitDocModal}
          onHide={() => {
            setShowSubmitDocModal(false);
          }}
          document={document}
          onChange={onChange}
        />
      )}
      {showInviteDocModal && (
        <DocumentInviteModal
          show={showInviteDocModal}
          onHide={() => {
            setShowInviteDocModal(false);
          }}
          document={document}
          onChange={onChange}
        />
      )}
      {showDeleteModal && (
        <DocumentDeleteModal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
          }}
          document={document}
          onChange={onChange}
        />
      )}
    </Row>
  );
};

type SubmissionRowProps = {
  inner?: Boolean;
  submission: DashboardSubmission;
};

const SubmissionRow = (props: SubmissionRowProps) => {
  const { inner, submission } = props;
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <Row
      className={"project-row submission-row" + (inner ? " inner" : "")}
      {...props}
    >
      <Col className="critique" sm={9}>
        <a
          href={`${backend_url}${submission.url}`}
          className="label sm-text btn-sm"
        >
          Critique
        </a>
        &nbsp;
        <span className="italic">{submission.group.name}</span>
      </Col>
      <Col className="action-buttons" style={{ minWidth: 140 }} sm={3}>
        <Button onClick={handleShow}>Download Critique</Button>
      </Col>

      <DocumentDownloadCritiques
        show={showModal}
        onHide={handleClose}
        submission={submission}
      />
    </Row>
  );
};

export const Wrapper = (props: any) => <div {...props} />;

type ProjectComponentProps = {
  open: Boolean;
  project: BaseProject;
  onChange: () => void;
};

const ProjectComponent = ({
  open,
  project,
  onChange,
}: ProjectComponentProps) => {
  return (
    <div>
      <Accordion.Toggle eventKey={project.id.toString()} as={Wrapper}>
        <ProjectRow project={project} open={open} onChange={onChange} />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={project.id.toString()} as={Wrapper}>
        <>
          {project?.documents?.map((document: any) => (
            <>
              <DocumentRow
                document={document}
                inner
                project={project}
                onChange={onChange}
              />
              {document.submissions?.map((submission: any) => (
                <SubmissionRow submission={submission} inner />
              ))}
            </>
          ))}
        </>
      </Accordion.Collapse>
    </div>
  );
};

type QuickSubmissionsComponentProps = {
  project: BaseProject;
  onChange: () => void;
};

const QuickSubmissionsProject = ({
  project,
  onChange,
}: QuickSubmissionsComponentProps) => {
  return (
    <>
      {project?.documents?.map((document: any) => (
        <>
          <DocumentRow
            document={document}
            project={project}
            onChange={onChange}
          />
          {document.submissions?.map((submission: any) => (
            <SubmissionRow submission={submission} />
          ))}
        </>
      ))}
    </>
  );
};

type CurrentTabComponentProps = {
  lastModified?: string;
  onChange: () => void;
};

const CurrentTab = ({ lastModified, onChange }: CurrentTabComponentProps) => {
  const [currentProjectsData] = useCurrentProjects();
  const [quickSubmissionsData] = useQuickSubmissions();
  const quickUploads = quickSubmissionsData.project?.documents;
  const [openProjectId, setOpenProjectId] = React.useState(lastModified);

  const onAccordionChange = (selectedId: any) => {
    setOpenProjectId(selectedId);
  };

  useEffect(() => {
    setOpenProjectId(lastModified);
  }, [lastModified]);

  return (
    <div className="tab-pane active current-tab" id="current">
      {currentProjectsData.projects.length === 0 &&
        currentProjectsData.completed &&
        (!quickUploads || quickUploads.length === 0) && (
          <Row key={0}>
            <Col className="no-projects">You have no current projects</Col>
          </Row>
        )}
      {currentProjectsData.projects.length > 0 && (
        <Accordion onSelect={onAccordionChange} defaultActiveKey={lastModified}>
          {currentProjectsData.projects?.map((project: any) => (
            <ProjectComponent
              project={project}
              open={openProjectId === project.id}
              onChange={onChange}
            />
          ))}
        </Accordion>
      )}
      <ReactPlaceholder
        type="text"
        rows={10}
        ready={currentProjectsData.completed}
        style={{ padding: 20 }}
      >
        <></>
      </ReactPlaceholder>
      <ReactPlaceholder
        type="text"
        rows={10}
        ready={quickSubmissionsData.completed}
        style={{ padding: 20 }}
      >
        {quickSubmissionsData?.project && (
          <QuickSubmissionsProject
            project={quickSubmissionsData.project}
            onChange={onChange}
          />
        )}
      </ReactPlaceholder>
    </div>
  );
};

export default CurrentTab;
