import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "custom-hooks/useFetch";
import Loading from "components/loading/Loading";
import GroupPublicProfileView from "./components/GroupPublicProfileView";

const GroupPublicProfile = () => {
  const { id } = useParams() as any;
  const [state, fetchData] = useFetch();
  const [groupProfileData, setGroupProfileData] = useState<any>(null);

  useEffect(() => {
    const fetchGroupProfile = async () => {
      try {
        const res = await fetchData({
          url: `api/v1.0/group/public/${id}/`,
          method: "GET",
        });
        setGroupProfileData(res);
      } catch (error) {
        console.error("Error fetching group profile data:", error);
      }
    };

    fetchGroupProfile();
  }, [id]);

  return (
    <>
      {(groupProfileData)
        ? <GroupPublicProfileView groupProfileData={groupProfileData} />
        : <Loading />
      }
    </>
  );
}

export default GroupPublicProfile;