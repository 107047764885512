import "./ArchivedEventDetail.scss";
import AuthenticationContext from "contexts/AuthenticationContext";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";

import { useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import DetailPage from "components/detailPage/DetailPage";
import { displayMonth } from "../../events/utils";
import Pill from "components/pill/Pill";
import EventDetailFacilitatorBio from "pages/events/eventDetail/facilitatorBio/EventDetailFacilitatorBio";
import RoundedGrayBox from "components/roundedGrayBox/RoundedGrayBox";
import Testimonial from "components/testimonial/Testimonial";
import { useSelector } from "react-redux";
import Config from "config";
import ReactPlayer from "react-player/vimeo";
import { CurrentUserState } from "types";

const backend_url = Config.BASE_API_URL;

const AdditionalMaterialItem = (props: any) => {
  const link = props.link;
  const icon = props.icon;
  const title = props.title;
  const description = props.description;
  return (
    <div className="additional-material-item">
      <a href={link}>
        <Row>
          <Col sm={2}>
            <span className={"icon " + icon} />
          </Col>
          <Col>
            <div className="title-wrapper">
              <div className="title">{title}</div>
            </div>
          </Col>
        </Row>
      </a>
      <Row>
        <Col>
          <p>{description}</p>
        </Col>
      </Row>
    </div>
  );
};

const Article = (props: any) => {
  const article = props.article;
  return (
    <AdditionalMaterialItem
      link={`${backend_url}lecture-series/article/${article.id}/`}
      icon="paper-icon"
      title={article?.title}
      description={article?.description}
    />
  );
};

const Podcast = (props: any) => {
  const podcast = props.podcast;
  return (
    <AdditionalMaterialItem
      link={`${backend_url}lecture-series/podcast/${podcast.id}/`}
      icon="mic-icon"
      title={podcast?.title}
      description={podcast?.description}
    />
  );
};

const Video = (props: any) => {
  const video = props.video;
  return (
    <AdditionalMaterialItem
      link={`${backend_url}lecture-series/video/${video.id}/`}
      icon="camera-icon"
      title={video?.title}
      description={video?.description}
    />
  );
};

const GreyMessageBox = (props: any) => {
  return (
    <div className="grey-message-box">
      <div className="vertical-center">
        <p>
          <b>
            Replays are available to Inked Voices members after the free trial
            ends.
          </b>
        </p>
        <p>
          <b>Already a member?</b> Log in to access.
        </p>
        <p>
          If you are a new member, please email brooke@inkedvoices.com to end
          your trial early and view.
        </p>
      </div>
    </div>
  );
};

const ArchivedEventDetail = (props: any) => {
  const { id } = useParams() as any;
  const authenticationContext = useContext(AuthenticationContext);
  const [event, setEvent] = useState<any>(null);
  const user: CurrentUserState = useSelector((state: any) => state.user);

  const fetchData = async () => {
    let url = `archive/${id}/?type=ResourceEvent`;
    const response = await sendRequest({
      url: url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setEvent(response);
  };

  useEffect(() => {
      fetchData();
  }, [authenticationContext]);

  const user_has_access =
    user?.data?.has_active_subscription && !user?.data?.is_in_free_trial_period;
  const dt = event && displayMonth(event?.live_at);
  const testimonialsNotEmpty = event?.testimonials?.length > 0;
  const additionalMaterialExists =
    event?.videos?.length > 1 ||
    event?.podcasts?.length > 0 ||
    event?.articles?.length > 0;
  const showRightColumn =
    (user_has_access && additionalMaterialExists) || testimonialsNotEmpty;
  const firstVideo = event?.videos ? event.videos[0] : null;
  return (
    <DetailPage className="event-detail archive-detail">
      <Row>
        <Col sm={{ span: 11, offset: 1 }}>
          <Pill>Archive</Pill>
          <h1>
            <ReactPlaceholder type="text" rows={3} ready={event}>
              {event?.title}
            </ReactPlaceholder>
          </h1>
          <h3 className="date">
            <ReactPlaceholder type="text" rows={1} ready={event}>
              {dt}
            </ReactPlaceholder>
          </h3>
        </Col>
      </Row>
      {!user_has_access && (
        <Row>
          <Col sm={{ span: 11, offset: 1 }}>
            <GreyMessageBox />
          </Col>
        </Row>
      )}
      {user_has_access && firstVideo && (
        <Row>
          <Col sm={{ span: 11, offset: 1 }}>
            <div className="webcast-container">
              <ReactPlayer
                url={firstVideo.url}
                height="500px"
                width="100%"
                controls={true}
              />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          sm={{ span: showRightColumn ? 8 : 11, offset: 1 }}
          className="left-column"
        >
          <p>
            <ReactPlaceholder type="text" rows={10} ready={event}>
              <p dangerouslySetInnerHTML={{ __html: event?.description }} />
            </ReactPlaceholder>
          </p>
          <Row>
            <EventDetailFacilitatorBio event={event} />
          </Row>
        </Col>
        {showRightColumn && (
          <Col sm={3} className="right-column">
            {additionalMaterialExists && (
              <div className="d-none d-md-block">
                <RoundedGrayBox>
                  <h6>Additional Event Materials</h6>
                  {event?.articles?.map((a: any) => (
                    <Article article={a} />
                  ))}
                  {event?.podcasts?.map((p: any) => (
                    <Podcast podcast={p} />
                  ))}
                  {event?.videos?.slice(1).map((v: any) => (
                    <Video video={v} />
                  ))}
                </RoundedGrayBox>
              </div>
            )}

            {event?.testimonials?.map((testimonial: any) => (
              <Testimonial testimonial={testimonial} />
            ))}
          </Col>
        )}
      </Row>
    </DetailPage>
  );
};

export default ArchivedEventDetail;
