import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";

type SubmissionCommentFormProps = {
  submissionId: number;
  parentId?: number;
  onPostComment: () => void;
};

export default function SubmissionCommentForm({
  submissionId,
  parentId,
  onPostComment,
}: SubmissionCommentFormProps) {
  const authenticationContext = useContext(AuthenticationContext);
  const [lastSavedTime, setLastSavedTime] = useState<Date | null>(null);
  const [commentId, setCommentId] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  const fetchDraft = async () => {
    const url = parentId
      ? `api/v1.0/submission/${submissionId}/comments/?self=true&parent=${parentId}&draft=true`
      : `api/v1.0/submission/${submissionId}/comments/?self=true&draft=true`;
    const response = await sendRequest({
      method: "GET",
      url: url,
      token: authenticationContext.token || "",
      body: null,
    });
    if (response.count > 0) {
      setCommentId(response.results[0].id);
      const textarea = ref.current;
      if (textarea) {
        textarea.value = response.results[0].text;
      }
    }
  };

  const submitComment = (text: string, draft: boolean = false) => {
    const url = commentId
      ? `api/v1.0/submission/${submissionId}/comments/${commentId}/`
      : `api/v1.0/submission/${submissionId}/comments/`;
    const method = commentId ? "PATCH" : "POST";
    sendRequest({
      method: method,
      url: url,
      body: JSON.stringify({ text: text, parent: parentId, draft: draft }),
      token: authenticationContext.token || "",
    }).then((response) => {
      !draft &&
        toast.success("Comment posted", {
          position: "bottom-right",
        });
      if (draft) {
        setCommentId(response.id);
        setLastSavedTime(new Date());
      } else {
        onPostComment();
      }
    });
  };

  const onMessagePost = (e: React.FormEvent) => {
    e.preventDefault();
    const textArea = (e.currentTarget as any)[0] as HTMLTextAreaElement;
    const text = textArea.value;
    submitComment(text, false);
    setLastSavedTime(null);
    textArea.value = "";
  };

  useEffect(() => {
    fetchDraft();
  }, []);

  return (
    <form
      id="comments-form"
      method="post"
      className="new-comment-form"
      onSubmit={onMessagePost}
    >
      <div className="textarea-holder">
        <textarea
          name="text"
          style={{ height: "30px" }}
          className="form-control"
          maxLength={5000}
          placeholder="Ask a question, answer one or make a comment."
          required={true}
          id="id_text"
          ref={ref}
          onChange={(e) => {
            const value = e.target.value;
            setTimeout(() => {
              if (value && value === e.target.value) {
                submitComment(value, true);
                setLastSavedTime(new Date());
              }
            }, 1000);
          }}
        ></textarea>
      </div>
      <p className="submit">
        <input
          type="submit"
          name="post"
          className="submit-post btn"
          value="Post"
        />
        {lastSavedTime && (
          <span className="last-sync pl-4">
            Saved as draft (
            {lastSavedTime.toLocaleTimeString("en-US", {
              timeStyle: "short",
            })}
            )
          </span>
        )}
      </p>
    </form>
  );
}
