import React from "react";
import { useSelector } from "react-redux";

import GroupEditProfileForm from "pages/groups/edit-profile/components/GroupEditProfileForm";

const NewGroup = () => {
  const user = useSelector((state: any) => state.user);

  return <GroupEditProfileForm />
};

export default NewGroup;
