import React, { useEffect, useContext, useState } from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";

import useFetch from "custom-hooks/useFetch";
import "./Contact.scss";


interface ContactModalProps {
  show: boolean;
  handleClose: () => void;
}

const Contact: React.FC<ContactModalProps> = ({ show, handleClose }) => {
  const [state, fetchData] = useFetch();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = {
      sender: email,
      message: message,
    };

    fetchData({
      url: `api/v1.0/contact/`,
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        toast.success("Thanks for contacting us! We'll reply shortly.");
        handleClose(); // Close the modal after successful submission
      })
      .catch((error: any) => {
        if (error) {
          toast.error(error);
        } else {
          console.error('Error submitting message:', error);
        }
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton closeLabel="" className="custom-close-button">
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} style={{ paddingBottom: '3%' }}>
              <Form.Group controlId="ContactForm">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <Form.Control
                  as="textarea"
                  rows={6}
                  placeholder="Send us a message! Your comments, feedback and questions are always welcome."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Button type="submit" className="btn">
                Submit Your Message
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Contact;