import React from "react";
import { Modal, Button } from 'react-bootstrap';

import './CloseGroupModal.scss';

import config from "../../config";

const BASE_API_URL = config.BASE_API_URL;


interface CloseGroupModalProps {
  show: boolean;
  handleClose: () => void;
  groupId: number;
  groupName: string;
}

const CloseGroupModal: React.FC<CloseGroupModalProps> = ({ show, handleClose, groupId, groupName }) => {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton closeLabel="" className="custom-close-button">
        <Modal.Title>Do you want to close this group?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This action will close {groupName}. Do you want to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" href={`${BASE_API_URL}group/close-group/${groupId}/`}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseGroupModal;
