import React, { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import GroupTab from './components/GroupTab';
import IndividualTab from './components/IndividualTab';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './Search.scss';


const SearchPage = () => {
  const [activeTab, setActiveTab] = useState('groups');

  return (
    <Container className="group-search profile-edit">
      <Row className="row">
        <Col className="col-md-12">
          <>
            {activeTab === 'groups' ? (
              <div className="search-title title-groups">
                <h1>Find a Group</h1>
                <h2>Find critique groups, accountability-only groups, discussion groups by genre, book studies, and writing challenges.</h2>
              </div>
            ) : (
              <div className="search-title title-individuals">
                <h1>Find a Member</h1>
                <h2>Look for potential writing partners or writers with interests that align with yours within the Inked Voices community.</h2>
              </div>
            )}
            <br/>
          </>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k!)}
            id="uncontrolled-tab-example"
            className="mb-3 nav-tabs"
          >
            <Tab eventKey="groups" title="Groups">
              <GroupTab />
            </Tab>
            <Tab eventKey="individuals" title="Individuals">
              <IndividualTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchPage;