import { useContext, useEffect, useState } from "react";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTr,
  IVTd,
  IVTableSeparator,
} from "./groupDetailsTabTable";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useSelector } from "react-redux";
import { CurrentUserState, SubmissionInCritique } from "types";
import {
  displayCondensedDate,
  displayShortDate,
  displayShortTime,
  getTimezone,
} from "pages/events/utils";
import DeleteSubmissionModal from "./deleteSubmissionModal";
import EditCritiqueModal from "./editCritiqueModal";
import MoveToCritiqueModal from "./moveToCritiqueModal";
import { on } from "events";
import EditDueDateModal from "./groupDetailEditDueDate";

const SubmissionCritiqueStatus = ({ submission }: { submission: any }) => {
  const status = submission.status_for_user;
  const [showEditCritiqueModal, setShowEditCritiqueModal] = useState(false);
  if (status === "Read Critiques") {
    return (
      <a
        className="btn submission green"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Give Critique") {
    if (submission.user_finished_critique) {
      return (
        <>
          <a
            className="submission btn purple"
            href="#"
            role="button"
            onClick={() => setShowEditCritiqueModal(true)}
          >
            Edit Critique
          </a>
          <EditCritiqueModal
            submission={submission}
            show={showEditCritiqueModal}
            onHide={() => {
              setShowEditCritiqueModal(false);
            }}
          />
        </>
      );
    }
    return (
      <a
        className="submission btn blue"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Finish Critique") {
    return (
      <a
        className="submission btn purple"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Submitted") {
    return (
      <a
        className="btn-default submission cutoff"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }

  return <>{submission.status_for_user}</>;
};

const SubmissionInReviewTab = ({
  submission,
  onDelete,
  onEdit,
  onMoveToCritique,
}: {
  submission: SubmissionInCritique;
  onDelete: (submission: SubmissionInCritique) => void;
  onEdit: (submission: SubmissionInCritique) => void;
  onMoveToCritique: (submission: SubmissionInCritique) => void;
}) => {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  return (
    <IVTr>
      <IVTd>
        {displayCondensedDate(submission.due_date).slice(0, -3)}
        <br />
        {displayShortTime(submission.due_date)}
        <br />({getTimezone()})
        <br />
        {submission.is_group_owner && (
          <a
            role="button"
            data-target="#myModal5"
            data-toggle="modal"
            onClick={() => onEdit(submission)}
          >
            Edit
          </a>
        )}
      </IVTd>

      <IVTd className="">
        <a
          className="cutoff"
          href={`/critique/all_for/${submission.id}/Submission/`}
        >
          {submission.title}
        </a>
      </IVTd>

      <IVTd>
        <a href={`/profile/view/${submission.author.profile_id}`}>
          {userState.data.id === submission.author.id
            ? "Me"
            : submission.author.pen_name}
        </a>
      </IVTd>

      <IVTd>
        <SubmissionCritiqueStatus submission={submission} />
      </IVTd>

      <IVTd>{submission.finished_critiques_count}</IVTd>

      <IVTd>
        {submission.can_be_removed && (
          <>
            <div className="pl-2">
              <a href="#" onClick={() => onDelete(submission)}>
                Remove
              </a>
            </div>
            <div className="pl-2">
              <a href="#" onClick={() => onMoveToCritique(submission)}>
                Move to Critique
              </a>
            </div>
          </>
        )}
      </IVTd>
    </IVTr>
  );
};

type GroupDetailReviewTabProps = {
  submissions: SubmissionInCritique[] | null;
  onChange?: () => void;
};

export default function GroupDetailReviewTab({
  submissions,
  onChange,
}: GroupDetailReviewTabProps) {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [submissionToDelete, setSubmissionToDelete] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToMoveToCritique, setSubmissionToMoveToCritique] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToEdit, setSubmissionToEdit] =
    useState<SubmissionInCritique | null>(null);

  return (
    <div
      className="tab-pane active current-tab"
      id="current"
      style={{ maxWidth: "100%", overflowX: "scroll" }}
    >
      <p>Review and discuss completed critiques.</p>
      <IVTable>
        <IVTableHead>
          <IVTr>
            <th scope="col" style={{ width: "60px" }}>
              Due
            </th>
            <th scope="col" style={{ width: "250px" }}>
              Title
            </th>
            <th scope="col" style={{ width: "150px" }}>
              Author
            </th>
            <th scope="col" style={{ width: "120px" }}>
              Status
            </th>
            <th scope="col" style={{ width: "60px" }}>
              Critiques
            </th>
            <th scope="col" style={{ width: "120px", paddingLeft: "1.5rem" }}>
              More
            </th>
          </IVTr>
        </IVTableHead>
        <IVTableBody>
          {submissions
            ?.filter((submission) => submission.author.id === userState.data.id)
            ?.map((submission) => (
              <SubmissionInReviewTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onEdit={setSubmissionToEdit}
                onMoveToCritique={setSubmissionToMoveToCritique}
              />
            ))}

          <IVTableSeparator cols={6} />

          {submissions
            ?.filter((submission) => submission.author.id != userState.data.id)
            ?.map((submission) => (
              <SubmissionInReviewTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onEdit={setSubmissionToEdit}
                onMoveToCritique={setSubmissionToMoveToCritique}
              />
            ))}
        </IVTableBody>
      </IVTable>
      {submissionToDelete && (
        <DeleteSubmissionModal
          submission={submissionToDelete}
          show={true}
          onHide={(deleted) => {
            setSubmissionToDelete(null);
            if (deleted) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToMoveToCritique && (
        <MoveToCritiqueModal
          submission={submissionToMoveToCritique}
          show={true}
          onHide={(moved) => {
            setSubmissionToMoveToCritique(null);
            if (moved) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToEdit && (
        <EditDueDateModal
          submission={submissionToEdit}
          show={true}
          onHide={(edited) => {
            setSubmissionToEdit(null);
            if (edited) {
              onChange && onChange();
            }
          }}
        />
      )}
    </div>
  );
}
