import { Annotation } from "types";
import $ from "jquery";
var Range = require("xpath-range").Range;

type NormalizedRange = {
  start: HTMLElement;
  end: HTMLElement;
  commonAncestor: HTMLElement;
};

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export const isMobile = () => {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

type AnnotatorTouchOptions = {
  user: {
    id: number;
    pen_name: string;
    can_delete: boolean;
  };
  shortenEnabled: boolean;
  user_list: {
    id: number;
    pen_name: string;
  }[];
  app: any;
  element: HTMLDivElement;
};

interface FullAnnotation extends Annotation {
  _marginObject: HTMLElement | null;
  _local: {
    highlights: HTMLSpanElement[];
  };
  _marginindex: number;
}

function trim(s: string) {
  if (typeof String.prototype.trim === "function") {
    return String.prototype.trim.call(s);
  } else {
    return s.replace(/^[\s\xA0]+|[\s\xA0]+$/g, "");
  }
}

function annotationFactory(contextEl: HTMLElement, ignoreSelector: any) {
  return function (ranges: any) {
    var text = [],
      serializedRanges = [];

    for (var i = 0, len = ranges.length; i < len; i++) {
      var r = ranges[i];
      text.push(trim(r.text()));
      serializedRanges.push(r.serialize(contextEl, ignoreSelector));
    }

    return {
      quote: text.join(" / "),
      ranges: serializedRanges,
    };
  };
}

const captureDocumentSelection = function (
  element: HTMLElement
): NormalizedRange[] {
  const ranges = [];
  const rangesToIgnore = [];
  const selection = global.getSelection();

  if (!selection || selection.isCollapsed) {
    return [];
  }

  for (let i = 0; i < selection.rangeCount; i++) {
    var r = selection.getRangeAt(i);
    const browserRange = new Range.BrowserRange(r);
    const normedRange = browserRange.normalize().limit(element);

    // If the new range falls fully outside our this.element, we should
    // add it back to the document but not return it from this method.
    if (normedRange === null) {
      rangesToIgnore.push(r);
    } else {
      ranges.push(normedRange);
    }
  }

  // BrowserRange#normalize() modifies the DOM structure and deselects the
  // underlying text as a result. So here we remove the selected ranges and
  // reapply the new ones.
  selection.removeAllRanges();

  for (let i = 0, len = rangesToIgnore.length; i < len; i++) {
    selection.addRange(rangesToIgnore[i]);
  }

  // Add normed ranges back to the selection
  for (let i = 0, len = ranges.length; i < len; i++) {
    var range = ranges[i],
      drange = document.createRange();
    drange.setStartBefore(range.start);
    drange.setEndAfter(range.end);
    selection.addRange(drange);
  }

  return ranges;
};

const moveEditor = (top: number, left: number) => {
  const editor = $(".annotator-outer.annotator-editor")[0];
  $(editor).show();
  const editorRadius = 140;
  const x = Math.max(editorRadius, left);
  editor.style.top = `${top}px`;
  editor.style.left = `${x}px`;
};

const hideEditor = () => {
  $(".annotator-outer.annotator-editor").hide();
};

export function annotatorTouch(options: AnnotatorTouchOptions) {
  const app = options.app;
  let pos = { top: 0, left: 0 };
  let annotation: null | FullAnnotation = null;
  var rectangle: DOMRect | undefined = undefined;
  const annotationsMap: { [key: number]: FullAnnotation } = {};
  const user = options.user;
  var makeAnnotation = annotationFactory(
    $("#annotated_content")[0].parentElement as HTMLElement,
    ".annotator-hl"
  );
  let ranges: NormalizedRange[] = [];
  let editingAnnotation: null | FullAnnotation = null;

  const startAnnotation = () => {
    $(annotateButton).hide();

    if (!iOS()) {
      const wrapper = $("#annotated_content")[0];
      ranges = captureDocumentSelection(wrapper);
    }

    if (ranges.length > 0) {
      annotation = makeAnnotation(ranges) as FullAnnotation;
      const top = rectangle
        ? document.documentElement.scrollTop +
          rectangle?.top +
          rectangle?.height +
          125
        : 0;
      const left = iOS() ? pos.left : pos.left + 100;
      moveEditor(top, left);
      app.annotations.create(annotation);
      $("a.annotator-delete").hide();
      editingAnnotation = null;
    } else {
      annotation = null;
    }
  };

  const getSelectionBoundingRectangle = () => {
    const selection = window.getSelection();
    const lastRange = selection?.getRangeAt(selection.rangeCount - 1);
    return lastRange?.getBoundingClientRect();
  };

  // create a button with the class annotate button
  const annotateButton = document.createElement("div");
  annotateButton.innerHTML = `<button type="button">Annotate</button>`;
  annotateButton.classList.add("annotator-adder");
  annotateButton.classList.add("annotator-adder-mobile");
  document.body.prepend(annotateButton);
  $(annotateButton).hide();
  $(annotateButton).on("click", () => {
    startAnnotation();
  });

  const handleSelection = (evt: { pageX: number; pageY: number }) => {
    $(".annotator-adder").hide();
    pos = {
      top: evt.pageY - 5,
      left: evt.pageX,
    };
    if (iOS()) {
      const wrapper = $("#annotated_content")[0];
      ranges = captureDocumentSelection(wrapper);
    }
    annotateButton.style.top = `${pos.top + 65}px`;
    annotateButton.style.left = `${pos.left}px`;
    $(annotateButton).show();
  };

  const handleHighlightClick = (
    annotation: FullAnnotation,
    highlight?: HTMLElement
  ) => {
    if (!user.can_delete) {
      return;
    }
    if (!highlight) {
      highlight = annotation._local.highlights[0];
    }

    $(".annotator-listing.annotator-widget").hide();

    if (annotation) {
      moveEditor(highlight.offsetTop, highlight.offsetLeft);
      app.annotations.update(annotation);
    }
    const editorButtonsWrapper = $(".annotator-save").parent();
    if (editorButtonsWrapper.children().length === 2) {
      editorButtonsWrapper.html(
        editorButtonsWrapper.html() + '<a class="annotator-delete">Delete</a>'
      );
      $("a.annotator-delete").on("click", () => {
        if (window.confirm("Are you sure you want to delete this comment?")) {
          hideEditor();
          app.annotations.delete(editingAnnotation);
        }
      });
    }
    $("a.annotator-delete").show();
  };

  const listenHighlightClick = (annotation: FullAnnotation) => {
    annotation._local.highlights.forEach(function (highlight: HTMLElement) {
      $(highlight).on("click", (event) => {
        handleHighlightClick(annotation);
        editingAnnotation = annotation;
      });
    });
  };

  setTimeout(() => {
    $(".annotator-hl").on("click", function (event) {
      const annotationId = parseInt(
        $(this).attr("data-annotation-id") as string
      );
      const annotation = annotationsMap[annotationId];
      handleHighlightClick(annotation, this);
    });
  }, 2000);

  const checkSelection = () => {
    const selection = window.getSelection();
    var text = selection?.toString();

    if (!text) {
      $(".annotator-adder").hide();
      return false;
    }
    rectangle = getSelectionBoundingRectangle();
    const wrapperRectangle = $("#annotated_content")[0].getBoundingClientRect();
    if (
      !rectangle ||
      !wrapperRectangle ||
      rectangle.top < wrapperRectangle.top ||
      rectangle.bottom > wrapperRectangle.bottom
    ) {
      return false;
    }
    return true;
  };

  return {
    annotationsLoaded: function (annotations: FullAnnotation[]) {
      if (iOS()) {
        document.addEventListener("touchend", (event) => {
          if (!checkSelection()) {
            return;
          }
          handleSelection({
            pageX: event.changedTouches[0].pageX,
            pageY: rectangle
              ? document.documentElement.scrollTop +
                rectangle?.top +
                rectangle?.height
              : 0,
          });
        });
      } else {
        if (isMobile()) {
          document.addEventListener("contextmenu", (evt) => {
            if (!checkSelection()) {
              return;
            }
            handleSelection(evt);
          });
        } else {
          document.addEventListener("selectionchange", () => {
            if (!checkSelection()) {
              return;
            }
            handleSelection({ pageX: pos.left, pageY: pos.top });
          });
        }
      }
      setTimeout(() => {
        annotations.forEach((annotation) => {
          annotationsMap[annotation.id] = annotation;

          listenHighlightClick(annotation);
        });
      }, 500);
    },
    beforeAnnotationCreated: function (annotation: FullAnnotation) {},
    annotationCreated: function (newAnnotation: FullAnnotation) {
      listenHighlightClick(newAnnotation);
    },
    annotationUpdated: function (updatedAnnotation: FullAnnotation) {
      listenHighlightClick(updatedAnnotation);
    },
  };
}
