import { Button, Tab, Tabs } from "react-bootstrap";
import GroupDetailCritiqueTab from "./groupDetailCritiqueTab";
import GroupDetailReviewTab from "./groupDetailReviewTab";
import { useContext, useEffect, useState } from "react";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import GroupDetailsSubmissionsTab from "./groupDetailsSubmissionsTab";
import {
  BaseDocument,
  GroupSubmissionSchedule,
  SubmissionInCritique,
} from "types";
import GroupDetailsFilesTab from "./groupDetailFilesTab";
import DocumentCreateModal from "pages/dashboard/components/modals/DocumentCreate";
import DocumentSubmitModal from "pages/dashboard/components/modals/DocumentSubmit";

export default function GroupDetailTabs({
  groupId,
  has_enough_credits,
}: {
  groupId: number;
  has_enough_credits: boolean;
}) {
  const authenticationContext = useContext(AuthenticationContext);
  const [submissionsToReview, setSubmissionsToReview] = useState<
    SubmissionInCritique[] | null
  >(null);
  const [submissionsToCritique, setSubmissionsToCritique] = useState<
    SubmissionInCritique[] | null
  >(null);

  const [submissionSchedule, setSubmissionSchedule] =
    useState<GroupSubmissionSchedule | null>(null);
  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [lastQuickSubmission, setLastQuickSubmission] =
    useState<BaseDocument | null>(null);

  const fetchSubmissionsToReview = () => {
    const url = `api/v1.0/group/${groupId}/submissions-to-review/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissionsToReview(response);
    });
  };

  const fetchSubmissionsToCritique = () => {
    const url = `api/v1.0/group/${groupId}/submissions-to-critique/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissionsToCritique(response);
    });
  };

  const fetchSubmissionsSchedule = () => {
    const url = `api/v1.0/group/${groupId}/submission-schedule/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissionSchedule(response);
    });
  };

  const openQuickSubmission = () => {
    // check number of credits
    setShowNewDocModal(true);
  };

  useEffect(() => {
    onChange();
    fetchSubmissionsSchedule();
  }, [groupId]);

  const onChange = () => {
    fetchSubmissionsToReview();
    fetchSubmissionsToCritique();
  };

  return (
    <>
      <span className="float-right quick-submission-button">
        <Button
          variant="outline"
          size="sm"
          style={{
            position: "absolute",
            marginLeft: -175,
            marginTop: 25,
            cursor: "pointer",
          }}
          onClick={() => openQuickSubmission()}
          disabled={!has_enough_credits}
        >
          Quick Submission
        </Button>
        {lastQuickSubmission && (
          <DocumentSubmitModal
            show={Boolean(lastQuickSubmission)}
            document={lastQuickSubmission}
            groupId={groupId}
            onHide={() => setLastQuickSubmission(null)}
            onChange={onChange}
          />
        )}
      </span>
      <DocumentCreateModal
        show={showNewDocModal}
        onHide={() => {
          setShowNewDocModal(false);
        }}
        onChange={(response: BaseDocument) => {
          setLastQuickSubmission({
            ...response,
            groups_available: response.groups_available.filter(
              (group) => group.id.toString() === groupId.toString()
            ),
          });
        }}
        submitToGroupId={groupId}
      />
      <Tabs defaultValue="critique" className="nav nav-tabs w-full">
        <Tab
          title="Critique"
          tabClassName="tab-purple"
          eventKey="critique"
          id="critique-tab"
        >
          <GroupDetailCritiqueTab
            submissions={submissionsToCritique}
            onChange={onChange}
          />
        </Tab>
        <Tab
          title="Review"
          tabClassName="tab-purple"
          eventKey="review"
          id="review-tab"
        >
          <GroupDetailReviewTab
            submissions={submissionsToReview}
            onChange={onChange}
          />
        </Tab>
        <Tab
          title="Submissions"
          tabClassName="tab-purple"
          eventKey="submissions"
          id="submissions-tab"
        >
          <GroupDetailsSubmissionsTab
            groupSubmissionSchedule={submissionSchedule}
          />
        </Tab>
        <Tab
          title="Files"
          tabClassName="tab-purple"
          eventKey="files"
          style={{ width: "100%" }}
          id="files-tab"
        >
          <GroupDetailsFilesTab groupId={groupId} />
        </Tab>
      </Tabs>
    </>
  );
}
