import config from "config";
import { BaseUser } from "types";
import { marginViewer } from "./annotator-margin-viewer";
import { mediumBreakpoint, smallBreakpoint } from "../../constants";
import $ from "jquery";
import { annotatorTouch, isMobile } from "./annotator-touch";

export function initAnnotator(
  user: BaseUser,
  submission_id: number,
  element: HTMLDivElement,
  token: string,
  all: boolean = false,
  allUsers?: BaseUser[],
  canDelete: boolean = true
) {
  const Annotator = require("annotator");
  var app = new Annotator.App();

  app.include(Annotator.ui.main, { element: element });

  // this is a hack to keep the highlight while the modal is open
  const beforeAnnotationCreated = app.modules[3].beforeAnnotationCreated;
  app.modules[3].beforeAnnotationCreated = (function (_super) {
    return function (annotation: any) {
      if (!annotation.id) {
        // @ts-ignore
        const self = this;
        $("a.annotator-cancel").on("click", function () {
          if (!annotation.id) {
            self.annotationDeleted(annotation);
          }
        });
        // @ts-ignore
        this.annotationCreated(annotation);
      }
      // @ts-ignore
      return _super.apply(this, arguments);
    };
  })(beforeAnnotationCreated);

  app.include(Annotator.storage.http, {
    prefix: `${config.BASE_API_URL}api/v1.0/note/`,
    urls: all
      ? {
          read: `${submission_id}/Submission/allnotes/?users=${allUsers
            ?.map((u) => u.id)
            .join(",")}`,
          search: `${submission_id}/Submission/allnotes/?users=${allUsers
            ?.map((u) => u.id)
            .join(",")}`,
        }
      : {
          create: `${submission_id}/${user.id}/Submission/note/`,
          read: `${submission_id}/${user.id}/Submission/note/:id`,
          update: `{id}`,
          destroy: `{id}`,
          search: `${submission_id}/${user.id}/Submission/note/`,
        },
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  if (document.body.clientWidth >= smallBreakpoint) {
    app.include(marginViewer, {
      user: { ...user, can_delete: canDelete },
      user_list: allUsers ? allUsers : [user],
      app: app,
    });
  }
  if (isMobile()) {
    app.include(annotatorTouch, {
      user: { ...user, can_delete: canDelete },
      user_list: allUsers ? allUsers : [user],
      app: app,
    });
  }

  app.start().then(function () {
    app.annotations.load();
  });

  return app;
}
