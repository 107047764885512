export const sexualContentOptions = [
  { value: 1, label: "Minimal - nothing more than kissing." },
  {
    value: 2,
    label:
      "Mild - may or may not include lovemaking. Sex is implied rather than explicit.",
  },
  {
    value: 3,
    label:
      "Hot - ranges from lovemaing to explicit sex (most romance novels).",
  },
  { value: 4, label: "Scorcher - borders on erotic. Very graphic sex." },
];