import "./Archive.scss";
import AuthenticationContext from "contexts/AuthenticationContext";
import { Container, Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import ArchiveItemsList from "./components/ArchiveItemsList";
import sendRequest from "services/dataService";
import FilterSet from "components/filters/filtersList/FiltersList";

const Archive = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const [items, setItems] = useState<any>(null);

  const fetchData = async () => {
    let url = `archive/`;
    const response = await sendRequest({
      url: url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setItems(response.results);
  };

  useEffect(() => {
      fetchData();
  }, [authenticationContext]);

  return (
    <Container className="ink-archive">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h1>Archive</h1>
          <h3>
            Develop your craft, deepen your process, and find inspiration in our
            library of webinars by authors, editors, and other industry
            professionals.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FilterSet
            id="archive-topic"
            title="Topic"
            path="lecture-series/resource-event-topics/"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <FilterSet
            id="archive-type-of-writing"
            title="Type of Writing"
            path="lecture-series/resource-event-type-of-writing/"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 11, offset: 1 }}>
          <ArchiveItemsList items={items} />
        </Col>
      </Row>
    </Container>
  );
};

export default Archive;
