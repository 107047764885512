import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import AuthenticationContext from "contexts/AuthenticationContext";
import GenreSelector from "components/genre-selector/GenreSelector";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import useFetch from "custom-hooks/useFetch";

import "./GroupTab.scss";
import { SearchProps } from "types";
import { typeOfGroupOptions } from "options/TypeOfGroup";
import { getGenreListIds } from "utils";
import PaginationButtons from "components/pagination-buttons/PaginationButtons";
import GroupItem from "../GroupItem";

const GroupTab = () => {
  const [state, fetchData] = useFetch();
  const [genres, setGenres] = useState([]);
  const authenticationContext = useContext(AuthenticationContext);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeFilters, setActiveFilters] = useState<SearchProps | null>(null); // Almacena los filtros activos
  const [pendingFilters, setPendingFilters] = useState<SearchProps | null>(
    null
  ); // Temp storage for filters

  const { register, handleSubmit, control, setValue, formState: { errors } } =
    useForm<SearchProps>({ mode: "onBlur" });

  useEffect(() => {
    fetchData({
      url: "api/v1.0/genres/",
      method: "GET",
      token: authenticationContext.token,
    }).then((response: any) => {
      setGenres(response);
    });
  }, [authenticationContext.token]);

  const buildUrl = (filters: SearchProps | null, page: number) => {
    let url = `group/api/search/?page=${page}&`;

    if (filters) {
      if (filters.query) {
        url += `q=${encodeURIComponent(filters.query)}&`;
      } else {
        url += `q=&`;
      }

      if (genres && genres.length > 0) {
        const genreIds = getGenreListIds(genres);
        genreIds.forEach((id: number) => {
          url += `genres=${id}&`;
        });
      }

      if (Array.isArray(filters.type_of_group)) {
        filters.type_of_group.forEach((type) => {
          url += `type_of_group=${type}&`;
        });
      } else if (filters.type_of_group) {
        url += `type_of_group=${filters.type_of_group}&`;
      }
    } else {
      url += `q=&`;
    }

    url += "model=group";
    return url;
  };

  const fetchGroups = async (filters: SearchProps | null, page: number) => {
    const url = buildUrl(filters, page);
    setIsLoading(true);
    try {
      const result = await fetchData({
        url: url,
        method: "GET",
        token: authenticationContext.token,
      });
      setSearchResults(result.results.groups);
      setTotalPages(Math.ceil(result.count / 6));
      setHasSearched(true);
    } catch (error) {
      console.error("Failed to fetch groups:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit: SubmitHandler<SearchProps> = (data) => {
    setPendingFilters(data); // Update pending filters
    setCurrentPage(1); // Reset the current page
    setHasSearched(true); // Set hasSearched to true to trigger the search
  };

  // Execute the search when the pending filters or the current page change
  useEffect(() => {
    if (pendingFilters !== null) {
      setActiveFilters(pendingFilters); // Update active filters
      setPendingFilters(null); // Reset pending filters
    }
  }, [pendingFilters]);

  useEffect(() => {
    if (hasSearched) {
      fetchGroups(activeFilters, currentPage);
    }
  }, [activeFilters, currentPage, hasSearched]);

  return (
    <div id="groups" className="group-tab">
      <form onSubmit={handleSubmit(onSubmit)}>
        <br />
        <Row>
          <Col md={12}>
            <label>Keywords</label>
            <input type="text" {...register("query")} />
          </Col>
        </Row>
        <Row className="group-additional-filters">
          <Col md={6}>
            <label>Type of Group</label>
            <hr />
            <ul id="id_type_of_group_tab" className="custom-checkbox">
              {typeOfGroupOptions.map((option, index) => (
                option.value !== 5 && (
                  <li
                    key={`id_type_of_group_tab_${index}`}
                    className="custom-checkbox"
                  >
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        value={option.value}
                        {...register("type_of_group")}
                        className="custom-checkbox"
                      />{" "}
                      {option.label}
                    </label>
                  </li>
                )
              ))}
            </ul>
          </Col>
          <Col md={6}>
            <label>Genres</label>
            <hr />
            <Controller
              name="genres"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <GenreSelector
                    genres={genres}
                    level={0}
                    onChange={(e: any) => {
                      setGenres(e);
                    }}
                  />
                );
              }}
            />
          </Col>
        </Row>
        <Row className="last">
          <Col md={9} className="main-btn">
            <input
              type="submit"
              className="btn btn-purple"
              value="Search"
              disabled={isLoading}
            />
            <a
              id="show_all_button"
              className={`button purple-link ${isLoading ? "disabled" : ""}`}
              onClick={() => {
                if (!isLoading) {
                  setCurrentPage(1);
                  setActiveFilters(null);
                  setHasSearched(true); // Set hasSearched to true to trigger the search
                }
              }}
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
            >
              Show all groups
            </a>
          </Col>
        </Row>
      </form>
      {isLoading ? (
        <div className="loading-indicator">
          <Spinner animation="border" role="status" />
          <span>Loading...</span>
        </div>
      ) : hasSearched && searchResults.length > 0 ? (
        <>
          <ul className="groups-result">
            {searchResults.map((group: any, index: number) => (
              <GroupItem key={index} group={group} />
            ))}
          </ul>
          <div className="pagination">
            <PaginationButtons
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => {
                if (!isLoading) {
                  setCurrentPage(page);
                }
              }}
              firstPageLabel="Newest"
              lastPageLabel="Oldest"
            />
          </div>
        </>
      ) : hasSearched ? (
        <p>No results found</p>
      ) : (
        <p>Use the search form to find groups.</p>
      )}
    </div>
  );
};

export default GroupTab;
