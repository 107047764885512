import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import config from "../../../../config";
import { truncateCharsHtml } from "../../../../utils";
import '../Profile.scss';
import InviteUserFromProfile from "./inviteUserFromProfile";
import { Membership } from "../../../../types";


interface ProfileViewProps {
  profileData: any;
  userMemberships: any;
}


const ProfileView: React.FC<ProfileViewProps> = ({ profileData, userMemberships }) => {
  const user = useSelector((state: any) => state.user);
  const { profile, super_category } = profileData;

  const AddressInfo = () => {
    return (
      <p>{profile.city}, {profile.state}, {profile.country}</p>
    );
  }

  const formatUrl = (url: string) => {
    if (!url) return '';
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      return `http://${url}`;
    }
  };

  return (
    <Container className="ink-profile">
      {profile.user === user.data.id && 
        <Row>
          <Col md={12}>
            <h1>My Profile</h1>
            <a href="/profile/edit/">Edit profile</a>
          </Col>
        </Row>
      }

      <Row>
        <div className="col-md-4 sidebar">
          {/* {data.return_params && <div><a href={config.BASE_API_URL + "results/?" + data.return_params}>Back to search</a></div>} */}
          <div className="profile-info">
            <a className="" href="#">
              <img className="media-object" src={profile.avatar} width="258px" height="258px" />
            </a>
            {(profile.city || profile.state || profile.country) && AddressInfo()}
          </div>

          {(profile.facebook || profile.twitter || profile.goodreads || profile.instagram || profile.linkedin || profile.personal_website) && (
            <>
              <h4>Contact Information</h4>
              {profile.facebook && (
                <a href={formatUrl(profile.facebook)} className="social ico-fb" target="_blank" rel="noopener noreferrer" />
              )}
              {profile.twitter && (
                <a href={formatUrl(profile.twitter)} className="social ico-tw" target="_blank" rel="noopener noreferrer" />
              )}
              {profile.goodreads && (
                <a href={formatUrl(profile.goodreads)} className="social ico-gr" target="_blank" rel="noopener noreferrer"/>
              )}
              {profile.instagram && (
                <a href={formatUrl(profile.instagram)} className="social ico-ig" target="_blank" rel="noopener noreferrer" />
              )}
              {profile.linkedin && (
                <a href={formatUrl(profile.linkedin)} className="social ico-li" target="_blank" rel="noopener noreferrer" />
              )}
              <br />
              {profile.personal_website && (
                <a href={formatUrl(profile.personal_website)} target="_blank" rel="noopener noreferrer" className="blue-link">
                  {profile.personal_website}
                </a>
              )}
              <br />
            </>
          )}

          {profile.user !== user.data.id &&
            <>
              <a className="btn contact-btn" href={config.BASE_API_URL + "messages/compose/" + profile.user}>Contact</a>
              <InviteUserFromProfile userId={profile.user} />
              <br/><br/>
            </>
          }

          {profile.organizations.length > 0 &&
            <>
              <h4 className="clearfix">Organizations</h4>
              <ul className="organizations">
                {profile.organizations.map((format: any) => (
                  <li><p>{format.name}</p></li>
                ))}
              </ul>
            </>
          }

          {userMemberships.length > 0 && (
            <>
              <h4>My Groups</h4>
              {userMemberships.map((membership: Membership) => {
                const { group } = membership;
                return (
                  <div className="group-info" key={group.id}>
                    <div className="row">
                      <div className="col-md-4">
                        <img src={config.BASE_API_URL + group.avatar} alt={group.name} width={75} height={75} />
                      </div>
                      <div className="col-md-8">
                        <h5>
                          <a href={`/${group.public_url}`} target="_blank" rel="noopener noreferrer">{group.name}</a>
                        </h5>
                        <div className="description" dangerouslySetInnerHTML={{ __html: truncateCharsHtml(group.description, 80) }} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="col-md-8">
          <div>
            <h1 style={{ display: 'inline' }}>
              {profile.pen_name}
              {profile.is_searching && <>&bull;</>}
            </h1>
            {profile.is_searching && (
              <span className="searching-group-status">
                {profile.get_searching_for_group_display}
              </span>
            )}
          </div>
          {profile.pronouns &&
            <h3>{profile.pronouns}</h3>
          }
          {profile.is_illustrator &&
            <h3>Author/Illustrator</h3>
          }
          {profile.bio &&
            <p>{profile.bio.split('\n').map((line: any, index: any) => <React.Fragment key={index}>{line}<br/></React.Fragment>)}</p>
          }
          {profile.genres2_set.length > 0 &&
            <>
              <h4>Genres</h4>
              <div style={{ marginBottom: '5px' }}>
                {profile.genres2_set.map((super_category: any) => (
                  <>
                    <h4 style={{ marginBottom: '10px' }}>{super_category.name}</h4>
                    <div style={{ marginBottom: '5px' }}>
                      {super_category.children.map((category: any) => (
                        <h5 style={{ marginBottom: '0' }}>
                          <span style={{ fontWeight: 600, fontSize: '15px' }}>{category.name}</span> ({category.children.map((genre: any) => genre.name).join(', ')})
                        </h5>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </>
          }
          {profile.experience &&
            <>
              <h4>Experience</h4>
              <p>{profile.experience}</p>
            </>
          }
          {profile.non_writing_experience &&
            <>
              <h4>Non-writing Experience</h4>
              <p>{profile.non_writing_experience}</p>
            </>
          }
          {profile.publication_set.length > 0 &&
            <>
              <h3>Publications</h3>
              <div className="clearfix">
                <ul className="publications-list">
                  {profile.publication_set.map((publication: any) => (
                    <li key={publication.title}>
                      <div>
                        <img src={publication.image} alt={`publication-${publication.title}`} width={80} height={100}/>
                        {publication.url ? (
                          <h4>
                            <a href={publication.url} className="blue-link">{publication.title}</a>
                          </h4>
                        ) : (
                          <h4>{publication.title}</h4>
                        )}
                        <p>
                          &#8203;{publication.publisher}
                          {publication.publisher && publication.year && ','}
                          {publication.year}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          }
          {profile.quote_set.length > 0 &&
            <>
              <h4>Favorite Quote</h4>
              <ul>
                {profile.quote_set.map((quote: any) => (
                  <>
                    <li className="quote">{quote.text}</li>
                    <li className="quote-author">-{quote.author}</li>
                  </>
                ))}
              </ul>
            </>
          }

          {profile.favorite_books.length > 0 &&
            <>
              <h4>Favorite Books</h4>
              <div className="clearfix">
                <ul className="list-tags">
                  {profile.favorite_books.map((book: any) => (
                    <li>{book.title}</li>
                  ))}
                </ul>
              </div>
            </>
          }

          {profile.favorite_authors.length > 0 &&
            <>
              <h4>Favorite Authors</h4>
              <div className="clearfix">
                <ul className="list-tags">
                  {profile.favorite_authors.map((author: any) => (
                    <li>{author.name}</li>
                  ))}
                </ul>
              </div>
            </>
          }

          <br/>
          <br/>

          {profile.any_writing_preference &&
            <h2>Writing Group Preferences</h2>
          }
          {profile.looking_in_a_group &&
            <>
              <h4>What I'm looking for in a group or partner</h4>
              <p>{profile.looking_in_a_group}</p>
            </>
          }
          {profile.critiquing_genres2_set.length > 0 &&
            <>
              <h4>Genres I'm open to critiquing</h4>
              <div style={{ marginBottom: '5px' }}>
                {profile.critiquing_genres2_set.map((super_category: any) => (
                  <>
                    <h4 style={{ marginBottom: '0', fontSize: '15px', marginTop: '0' }}>{super_category.name}</h4>
                    <div style={{ marginBottom: '5px' }}>
                      {super_category.children.map((category: any) => (
                        <h5 style={{ marginBottom: '0' }}>
                          <span style={{ fontWeight: 600, fontSize: '15px' }}>{category.name}</span>
                          ({category.children.map((genre: any) => genre.name).join(', ')})
                        </h5>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </>
          }
          {profile.critiquing_style &&
            <>
              <h4>Critique style</h4>
              <p>{profile.critiquing_style}</p>
            </>
          }
          {profile.looking_for_in_critique &&
            <>
              <h4>What I'm looking for in a critique</h4>
              <p>{profile.looking_for_in_critique}</p>
            </>
          }
          { (user.data.restricted_admin || (user.data.is_admin && profile.writing_sample)) && (
            <>
              <h4>Writing Sample</h4>
              <p>{profile.writing_sample.split('\n').map((line: any, index: any) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}</p>
            </>
          )}
        </div>
      </Row>
    </Container>
  );
}

export default ProfileView;
